import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./header.css";

export class SiteHeader extends Component {

    redirect = (url) => {
        this.props.history.push("/" + url);
    }


    render() {
        return (
            <div>
                <header className="site-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-3 visible-xs text-left head-mobile-nav">
                                <a href="" id="mobile-menu-trigger">
                                    <span className="icon-menu"></span>
                                </a>
                            </div>

                            <div className="col-xs-6">
                                <div className="logo">
                                    <a href="/" title="Logo">
                                        <img src="/Assets/logo/logo.png"
                                            alt="Logo" /></a>
                                </div>
                            </div>

                            <div className="col-xs-3 text-right visible-xs head-mobile-nav">
                                <span onClick={() => this.redirect("cart")}>
                                    <span className="icon-basket"></span>
                                </span>
                            </div>

                            <div id="useraccount-wrapper" className="col-sm-7 col-xs-12">
                                {/* <div className="social_icons clearfix hidden-xs">
                                    <h3> Stay Connected</h3>
                                    <ul className="social-icons list-unstyled list-inline">

                                        <li><a href="" className="facebook" target="_blank" title="Like Us on Facebook"><span
                                            className="icon-facebook"></span></a></li>


                                        <li><a href="" className="twitter" target="_blank" title="Follow Us on Twitter"><span
                                            className="icon-twitter"></span></a></li>


                                        <li><a href="" className="youtube" target="_blank" title="Subscribe to our Channel"><span
                                            className="icon-youtube"></span></a></li>


                                        <li><a href="" className="pinterest" target="_blank" title="Follow Us on Pinterest"><span
                                            className="icon-pinterest"></span></a></li>


                                        <li><a href="" className="instagram" target="_blank" title="Follow Us on Instagram"><span
                                            className="icon-instagram"></span></a></li>

                                    </ul>
                                </div> */}

                                {/* <div id="FRAME_SEARCH">
                                    <div id="searchBox" className="searchBox clearfix">
                                        <form method="get" name="searchForm">
                                            <div className="search-form">
                                                <input type="text" id="searchlight" name="keyword" placeholder="Search"
                                                    className="search-text form-control" />
                                                <button type="submit" className="search-submit"><span
                                                    className="icon-search"></span></button>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteHeader));
