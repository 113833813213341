import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import helpers from '../../helpers';

export class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cartItems: []
        }
    }

    componentDidMount() {
        this.updateCart();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.cartUpdate !== nextProps.cartUpdate) {
            this.updateCart();
        }
    }

    updateCart = () => {
        let cartData = helpers.getLocalStorage('cartItems');
        if (typeof cartData !== 'undefined' && cartData !== null && cartData !== "") {
            try {
                let cartItems = JSON.parse(cartData);
                this.setState({ cartItems });
            } catch (e) {
                console.log("e", e);
            }
        }
    }

    redirect = (url) => {
        this.props.history.push("/" + url);
    }

    render() {

        let { cartItems } = this.state;

        return (
            <div>
                <section className="top-nav hidden-xs">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <div className="clearfix">
                                    <div id="FRAME_MENU">
                                        <nav id="menulinks-outer" className="menulinks">
                                            <ul id="menulinks" className="clearfix">
                                                {/* <li>Call Us <a href="tel:"></a></li> */}
                                                <li>
                                                    <span className="menu cursor-pointer" onClick={() => this.redirect("home")}>Home</span>
                                                </li>
                                                <li>
                                                    <a className="menu cursor-pointer" rel="noreferrer" target="_blank" href="https://ullasincense.co.in/about%20-us.html" >About Us</a>
                                                </li>
                                                <li>
                                                    <span className="menu cursor-pointer" onClick={() => this.redirect("contact-us")}>Contact Us</span>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 useraccount-wrapper">
                                <div className="minicart hidden-xs">
                                    <span className="minicart-inner-top" onClick={() => this.redirect("cart")}>
                                        <i className="icon-shopping-bag cart-icon"></i> View ({cartItems.length}) items
						            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Cart {...cartProps} /> */}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cartUpdate: state.reducer.cartUpdate
    }
}


const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
