import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from "reactstrap";
import "./product-details.css";
import axios from "axios";
import helpers from "../../helpers";
import Loader from "../Common/Loader";
import QuickView from "./QuickView";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { updateCart } from "../../actions/cartActions";
import { bindActionCreators } from "redux";
const restURL = helpers.restURL + "dashboard_products.php";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export class NewReleases extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // newReleaseItems: [{ "id": "5", "name": "dirgin de montserrat", "description": "eee", "instock": "yes", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_6.jpg", "category_id": "18", "category_name": "dirgin de montserrat" }, { "id": "4", "name": "Gardenia", "description": "Gardenia", "instock": "yes", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_7.jpg", "category_id": "21", "category_name": "Sandal" }, { "id": "3", "name": "BLACK NAGCHAMPA", "description": "BLACK NAGCHAMPA", "instock": "yes", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_3.jpg", "category_id": "1", "category_name": "Sandal" }, { "id": "2", "name": "CANNABIS", "description": "CANNABIS", "instock": "no", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_4.jpg", "category_id": "1", "category_name": "Sandal" }, { "id": "1", "name": "CLOVE", "description": "CLOVE", "instock": "no", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_5.jpg", "category_id": "1", "category_name": "Sandal" }],
            newReleaseItems: [],
            errorMessage: "Loading...",
            productData: {},
            showQuickView: false,
            showLoading: "hide"
        }
    }

    componentDidMount() {
        this.getNewReleaseItems();
    }

    getNewReleaseItems = () => {
        let restEnd = "";
        if (this.props.type === "newreleses") {
            restEnd = "newreleaseitems";
        } else if (this.props.type === "similaritems") {
            restEnd = "categoryitems&category_id=" + this.props.categoryID;
        } else if (this.props.type === "featureditems") {
            restEnd = "featureditems";
        }

        this.setState({ showLoader: "show" });
        let url = restURL + "?rest=" + restEnd;
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processNewReleaseItems(data);
            } else {
                this.setState({ errorMessage: "Failed to get New Release items. Please try again later." })
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    processNewReleaseItems = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ newReleaseItems: data, errorMessage: "" });
        } else {
            this.setState({ newReleaseItems: [], errorMessage: "No Products found !" })
        }
    }

    getProductImage = (data) => {
        let imagePath = data.logo_path.split("/var/www/html/exportapp/public/");
        imagePath = imagePath[1];
        return (
            <img src={imagePath} alt={data.name}
                className="img-responsive" style={{ width: "213px", height: "191px" }} />
        )
    }

    showProductDetails = (data) => {
        this.setState({ showQuickView: true, productData: data });
    }

    hideQuickView = () => {
        this.setState({ showQuickView: false, productData: {} });
    }

    addToCart = (productData) => {
        this.setState({ showLoading: "show" }, () => {
            let qty = 1, description = "";
            helpers.addTocart(productData, qty, description);
        });

        setTimeout(() => {
            this.props.updateCart();
            this.setState({ showLoading: "hide" })
        }, 500);
    }

    redirectToDetails = (data) => {
        this.props.history.push("/product-details?id=" + data.id);
    }

    redirectToAll = () => {
        if (this.props.type === "newreleses") {
            this.props.history.push("all-products?type=new");
        } else if (this.props.type === "similaritems") {
            this.props.history.push("all-products?type=new");
        } else if (this.props.type === "featureditems") {
            this.props.history.push("all-products?type=new");
        }
    }

    render() {

        let { newReleaseItems, productData, showQuickView } = this.state;
        let quickViewProps = {
            showQuickView,
            productData,
            ...productData,
            hideQuickView: this.hideQuickView
        }

        return (
            <div className="home-page-content">
                <Loader showLoader={this.state.showLoading} />
                <div id="modNewReleases" className="products-section">
                    <div className="container">
                        <h3 className="products-header">
                            <span>{this.props.title}</span>
                            <Button className="pull-right" color="primary" onClick={() => this.redirectToAll()}>View All</Button>
                        </h3>
                        {newReleaseItems.length > 0 ?
                            <Carousel
                                additionalTransfrom={0}
                                arrows={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                centerMode={false}
                                containerClass="container-with-dots"
                                draggable
                                focusOnSelect={false}
                                infinite
                                keyBoardControl
                                minimumTouchDrag={80}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={responsive}
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {newReleaseItems.map((data, index) => (
                                    <div className="product-item" data-animation="fadeInUp" data-timeout="500" key={index}>
                                        <div className="img cursor-pointer">
                                            {this.getProductImage(data)}
                                            <button className="quickview" onClick={() => this.showProductDetails(data)}>Quick View</button>
                                        </div>
                                        <div className="product-content text-center">
                                            <div className="name cursor-pointer" onClick={() => this.redirectToDetails(data)}>
                                                <span>{data.name}</span>
                                            </div>

                                            <div className="status">
                                                <span className="availability">
                                                    {data.instock === "yes" ? "In Stock." :
                                                        <span className="out-of-stock">Out of Stock !</span>}
                                                </span>
                                            </div>

                                            <div className="action">
                                                <Button onClick={() => this.addToCart(data)} className="add-to-cart btn btn-default btn-inverse">
                                                    I'm Intrested</Button>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                            : <React.Fragment />}
                        <h5 className="text-center">{this.state.errorMessage}</h5>
                    </div>
                </div>

                <QuickView {...quickViewProps} />

            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateCart: updateCart }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewReleases));
