import React, { PureComponent } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

export default class WarningModal extends PureComponent {
    render() {

        let { showAlert, title, message, confirmBtnText } = this.props;

        return (
            <div>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={confirmBtnText}
                    confirmBtnBsStyle="danger"
                    title={title}
                    btnSize="md"
                    onConfirm={() => this.props.confirmAction()}
                    onCancel={() => this.props.closeAlert()}
                    cancelBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{

                        width: "max-content",
                        fontSize: "14px",
                        border: "1px solid grey"
                    }}
                    show={showAlert}
                >
                    <span style={{ color: "#dc3545" }}>{message}</span>
                </SweetAlert>
            </div>
        )
    }
}
