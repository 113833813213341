import React, { Component } from 'react'
import { connect } from 'react-redux'
import CarouselComponent from "./Common/Carousel";
// import FeaturedProducts from "./Products/FeaturedProducts";
import NewReleases from "./Products/NewReleases";

export class Home extends Component {
    render() {
        return (
            <div className="home-page leftbar rightbar qc-enabled qv-enabled not-logged-in">
                <CarouselComponent />
                {/* <FeaturedProducts /> */}
                <NewReleases title="Featured Products" type="featureditems" />
                <NewReleases title="New Releases" type="newreleses" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
