import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import "./common.css";

class PageNotFound extends Component {

    handleRedirect(url) {
        this.props.history.push(url);
    }

    render() {
        return (
            <div className="container-fluid page-not-found">
                <div className="content-wrapper d-flex align-items-center text-center error-page">
                    <Row>
                        <Col lg="12" style={{ marginTop: "150px" }}>
                            <Col lg="5">
                                <h1 style={{ fontSize: "12rem" }}>404</h1>
                            </Col>
                            <Col lg="6" style={{ paddingTop: "40px" }}>
                                <Col lg="12" className="error-page-divider text-lg-left pl-lg-4">
                                    <h1>SORRY!</h1>
                                    <h3>The page you’re looking for was not found.</h3>
                                </Col>
                            </Col>
                            <Col lg="1"></Col>
                        </Col>
                        <Col lg="5"></Col>
                        <Col md="6" lg="6" className="text-left mt-xl-2" style={{ marginLeft: "20px" }}>
                            <h5 className="font-weight-large" style={{ cursor: "pointer" }}
                                onClick={() => this.handleRedirect("/home")}>Back to home</h5>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageNotFound));