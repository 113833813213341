import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from "./Header";
import SiteHeader from "./SiteHeader";
import Menu from "../Common/Menu";

export class ExportAppHeader extends Component {
    render() {
        return (
            <div>
                <Header />
                <SiteHeader />
                <Menu />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ExportAppHeader)
