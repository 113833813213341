import React, { Component } from 'react'
import { connect } from 'react-redux'

export class AboutUs extends Component {
    render() {
        return (
            <div>
                <section class="breadcrumnb" aria-label="itemsgrid" role="main">

                </section>
                <section class="page_header" aria-label="page title" role="main">
                    <div class="container">
                        <h1 class="page_heading">
                            About Us
            </h1>
                    </div>
                </section>
                <section id="extrapage" class="page-content ">
                    <div class="container">
                        <div class="content-area">

                            <div id="content" class="content tc-pagebuilder">
                                <div>Sample Store<br /></div>
                                <div><br /></div>
                                <p class="MsoNormal">Thanks so much for visiting our online store. Our team is
                                excited to provide you with an impeccable online shopping experience and
                                remains available to assist at any time. If you have questions, comments or
                                concerns about your order or the content found within this website, please feel
                                free to contact us via telephone or email and one of our experienced team
                                members will get back to you right away.&nbsp;
                                Again, thanks for visiting our store and we look forward to serving you in
                                the future. &nbsp;
                    </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)
