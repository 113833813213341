import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import { Button } from "reactstrap";
import AddNewCategory from "./AddNewCategory";
import "./product.css";
import axios from "axios";
import helpers from "../../../helpers";
import Loader from "../../Common/Loader";
import WarningModal from "../../Common/WarningModal";
import ToastMessage from "../../Common/ToastMessage";
const restURL = helpers.restURL + "admin/category.php";

export class ProductCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryData: [],
            showAddModal: false,
            showAdd: false,
            showUpdate: false,
            editData: {},
            showLoader: "hide",
            showWarningModal: false,
            showToast: false,
            message: "",
            toastType: ""
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    showToast = (message, toastType) => {
        this.setState({
            showToast: true,
            message: message,
            toastType: toastType
        });
        setTimeout(() => {
            this.setState({ showToast: false })
        }, 500);
    }

    getCategories = () => {
        this.setState({ showLoader: "show" });
        let url = restURL + "?rest=getcategories";
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processCategoryData(data);
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    addNewCategory = (data) => {
        this.setState({
            showAddModal: false,
            showAdd: false,
            showUpdate: false,
            showLoader: "show"
        });
        axios({
            method: "POST",
            url: restURL,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processCategoryData(data);
                this.showToast("Category Addedd successfully !", "success");
            } else {
                this.showToast("Failed to add Category", "error");
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    updateCategory = (data) => {
        this.setState({
            showAddModal: false,
            showAdd: false,
            showUpdate: false,
            showLoader: "show"
        });

        axios({
            method: "PUT",
            url: restURL,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processCategoryData(data);
                this.showToast("Category Updated successfully !", "success");
            } else {
                this.showToast("Failed to update Category", "error");
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    deleteCategory = () => {
        this.setState({
            showWarningModal: false,
            showLoader: "show"
        });

        let data = {
            rest: "deletecategory",
            category_id: this.state.editData.id
        };

        axios({
            method: "DELETE",
            url: restURL,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processCategoryData(data);
                this.showToast("Category deleted successfully !", "success");
            } else {
                this.showToast("Failed to delete Category", "error");
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    processCategoryData = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ categoryData: data });
        } else {
            this.setState({ categoryData: [] })
        }
    }

    createCustomButtonGroup = (props) => {
        return (
            <ButtonGroup sizeClass='btn-group-md'>
                <Button className="float-left" onClick={() => this.toggleAddModal(true)}>Add New Category</Button>
            </ButtonGroup>
        );
    }

    actionFormatter = (cell, row) => {
        return (
            <div sizeclass='btn-group-md' className="btn-action-group">
                <Button className="float-left" onClick={() => this.setEditData(row)}>
                    <i className="fa fa-pencil-square-o"></i> Edit
                </Button>
                <Button className="float-left" color="danger" onClick={() => this.setDeleteData(row)}>
                    <i className="fa fa-trash-o"></i> Delete
                </Button>
            </div>
        );
    }

    toggleAddModal = (data) => {
        this.setState({ showAddModal: data, showAdd: data, showUpdate: false, editData: {} });
    }

    setDeleteData = (data) => {
        this.setState({
            editData: data,
            showWarningModal: true
        });
    }

    setEditData = (data) => {
        this.setState({
            editData: data,
            showAddModal: true,
            showAdd: false,
            showUpdate: true
        })
    }

    toggleWarningModal = () => {
        this.setState({ showWarningModal: false });
    }

    render() {

        const options = {
            page: 1,
            sizePerPage: 10,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: 'Prev',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            paginationPosition: 'bottom',
            hidePageListOnlyOnePage: true,
            hideSizePerPage: true,
            btnGroup: this.createCustomButtonGroup,
        };

        let { categoryData, showAddModal, showAdd, editData, showUpdate, showWarningModal, showToast, message, toastType } = this.state;

        let addCategoryProps = {
            showAddModal,
            showAdd,
            toggleAddModal: this.toggleAddModal,
            editData,
            showUpdate,
            categoryData,
            addNewCategory: this.addNewCategory,
            updateCategory: this.updateCategory
        }

        let confirmProps = {
            showAlert: showWarningModal,
            title: "Are you sure want to delete ?",
            message: "You will not be able to recover this !",
            closeAlert: this.toggleWarningModal,
            confirmAction: this.deleteCategory,
            confirmBtnText: "Delete",
            clearOnSave: false
        }

        let toastProps = { showToast, message, toastType };

        return (
            <div>
                <Loader showLoader={this.state.showLoader} />
                <ToastMessage {...toastProps} />
                <h1 className="text-center">Product Categories</h1>
                <BootstrapTable pagination={true} data={categoryData} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} trClassName='table-format' options={options} search>
                    <TableHeaderColumn dataAlign='left' width='150' dataField="category_name" thStyle={{ fontSize: "12px" }} isKey={true} dataSort={true} >Name</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='150' dataField="description" thStyle={{ fontSize: "12px" }} dataSort={true} >Description</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="id" thStyle={{ fontSize: "12px" }} dataSort={false} dataFormat={this.actionFormatter}>Action</TableHeaderColumn>
                </BootstrapTable>

                <AddNewCategory {...addCategoryProps} />

                <WarningModal {...confirmProps} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory)
