import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Alert, Row, Input, FormGroup, Label } from "reactstrap";
import ProductCategory from "./Product/ProductCategory";
import ProductList from "./Product/ProductList";
import Requests from "./Product/Requests";
import "./admin-home.css";

export class AdminHome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "product-category",
            isAuthenticated: true,
            username: "",
            password: "",
            type: "",
            errorMessage: ""
        }
    }

    handelTabChange = (tabName) => {
        this.setState({ activeTab: tabName });
    }

    getActiveClass = (tabName) => {
        return this.state.activeTab === tabName ? 'active-tab' : "";
    }

    getTabToLoad = (tabName) => {
        let tabToLoad = "";

        if (tabName === "product-category") {
            tabToLoad = <ProductCategory />
        } else if (tabName === "products") {
            tabToLoad = <ProductList />
        } else if (tabName === "purchase-orders") {
            tabToLoad = <Requests />
        }

        return tabToLoad;
    }

    handleRequestFormChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateAndMakeRequest = () => {

        let confirmUsername = "admin", confirmPassword = "admin@1234";
        let { username, password } = this.state;
        if (username.trim() === confirmUsername && password.trim() === confirmPassword) {
            this.setState({ type: "success", errorMessage: "Logged in successfully !" });
            setTimeout(() => {
                this.setState({ isAuthenticated: true, });
            }, 2000);

        } else {
            this.setState({ type: "danger", errorMessage: "Please check Login credentials !" });
            setTimeout(() => {
                this.setState({ isAuthenticated: false, });
            }, 2000);
        }
    }

    render() {
        let { isAuthenticated, type, errorMessage } = this.state;

        return (
            <Col sm="12" md="12" lg="12" xs="12">
                <div className="jumbotron">
                    <h1 className="text-center">Admin Home</h1>
                </div>
                {isAuthenticated ?
                    <React.Fragment>
                        <div className="menu-tabs">
                            <Button size="lg" className={this.getActiveClass("product-category")} onClick={() => this.handelTabChange("product-category")}>Product Category</Button>
                            <Button size="lg" className={this.getActiveClass("products")} onClick={() => this.handelTabChange("products")}>Products</Button>
                            <Button size="lg" className={this.getActiveClass("purchase-orders")} onClick={() => this.handelTabChange("purchase-orders")}>Purchase Orders</Button>
                            <span className="pull-right logout-btn" onClick={() => this.setState({ isAuthenticated: false })}>Logout</span>
                        </div>
                        <div className="padding-20">
                            {this.getTabToLoad(this.state.activeTab)}
                        </div>
                    </React.Fragment> :
                    <div className="container">
                        <Row>
                            <Col sm="12" md="8" xs="12" lg="6">
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type="text" name="username" onChange={(e) => this.handleRequestFormChange(e)} placeholder="Username" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Email Address</Label>
                                    <Input type="password" name="password" onChange={(e) => this.handleRequestFormChange(e)} placeholder="Password" />
                                </FormGroup>

                                <Button className="request-btn"
                                    onClick={() => this.validateAndMakeRequest()}
                                    color="info">
                                    <i className="fa fa-lock"></i> Login</Button>
                            </Col>
                        </Row>

                        {errorMessage !== "" ?
                            <div className="error-message-block">
                                <Alert color={type} fade={false} style={{ marginBottom: "20px" }}>
                                    {errorMessage}
                                </Alert>
                            </div> : <React.Fragment />}

                    </div>
                }


            </Col>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome)
