import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import { Button } from "reactstrap";
import AddProducts from "./AddProducts";
import axios from "axios";
import helpers from "../../../helpers";
import Loader from "../../Common/Loader";
import WarningModal from "../../Common/WarningModal";
import ToastMessage from "../../Common/ToastMessage";
import "./product.css";
const restURL = helpers.restURL + "admin/products.php";
const categoryRestURL = helpers.restURL + "admin/category.php";

export class ProductList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productsData: [],
            categoryData: [],
            showAddModal: false,
            showAdd: false,
            showUpdate: false,
            editData: {},
            showLoader: "hide",
            showWarningModal: false,
            showToast: false,
            message: "",
            toastType: ""
        }
    }

    componentDidMount() {
        this.getCategories();
        this.getProductList();
    }

    showToast = (message, toastType) => {
        this.setState({
            showToast: true,
            message: message,
            toastType: toastType
        });
        setTimeout(() => {
            this.setState({ showToast: false })
        }, 500);
    }

    processProductData = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ productsData: data });
        } else {
            this.setState({ productsData: [] })
        }
    }

    processCategoryData = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ categoryData: data });
        } else {
            this.setState({ categoryData: [] })
        }
    }

    getProductList = () => {
        this.setState({ showLoader: "show" });
        let url = restURL + "?rest=getproducts";
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processProductData(data);
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    getCategories = () => {
        this.setState({ showLoader: "show" });
        let url = categoryRestURL + "?rest=getcategories";
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processCategoryData(data);
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    addProduct = (data) => {

        this.setState({
            showAddModal: false,
            showAdd: false,
            showUpdate: false,
            showLoader: "show"
        });

        axios({
            method: "POST",
            url: restURL,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json",
            dataType: "json",
            processData: false,
            contentType: false,
            data: data,
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processProductData(data);
                this.showToast("Product Addedd successfully !", "success");
            } else {
                this.showToast("Failed to add Product", "error");
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    updateProduct = (data, withFile) => {

        this.setState({
            editData: {},
            showAddModal: false,
            showAdd: true,
            showUpdate: false,
            showLoader: "show"
        }, () => {
            if (withFile) {
                axios({
                    method: "POST",
                    url: restURL,
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    responseType: "json",
                    dataType: "json",
                    processData: false,
                    contentType: false,
                    data: data,
                }).then(res => {
                    if (res.data.success) {
                        let data = res.data.data;
                        this.processProductData(data);
                        this.showToast("Product updated successfully !", "success");
                    } else {
                        this.showToast("Failed to update Product", "error");
                    }
                    this.setState({ showLoader: "hide" });
                }).catch(error => {
                    console.log(error);
                });
            } else {
                axios({
                    method: "PUT",
                    url: restURL,
                    data: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    responseType: "json"
                }).then(res => {
                    if (res.data.success) {
                        let data = res.data.data;
                        this.processProductData(data);
                        this.showToast("Product updated successfully !", "success");
                    } else {
                        this.showToast("Failed to update Product", "error");
                    }
                    this.setState({ showLoader: "hide" });
                }).catch(error => {
                    console.log(error);
                });
            }
        });
    }

    updateStockStatus = (status, id) => {
        this.setState({ showLoader: "show" });
        let url = restURL + "?rest=updatestockstatus&status=" + status + "&product_id=" + id;
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processProductData(data);
                this.showToast("Stock status updated successfully !", "success");
            } else {
                this.showToast("Failed to update Stock status", "error");
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    setDeleteData = (data) => {
        this.setState({
            editData: data,
            showWarningModal: true
        });
    }

    toggleWarningModal = () => {
        this.setState({ showWarningModal: false });
    }


    deleteProduct = () => {
        this.setState({
            showWarningModal: false,
            showLoader: "show"
        });

        let data = {
            rest: "deleteproduct",
            product_id: this.state.editData.id
        };

        axios({
            method: "DELETE",
            url: restURL,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processProductData(data);
                this.showToast("Product deleted successfully !", "success");
            } else {
                this.showToast("Failed to delete Product", "error");
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }


    createCustomButtonGroup = (props) => {
        return (
            <ButtonGroup sizeClass='btn-group-md'>
                <Button className="float-left" onClick={() => this.toggleAddModal(true)}>Add New</Button>
            </ButtonGroup>
        );
    }

    logoFormatter = (cell, row) => {
        cell = cell.split("/var/www/html/exportapp/public/");
        cell = cell[1];
        return (
            <div>
                <img itemProp="image"
                    src={cell}
                    style={{
                        height: "50%", width: "50%", maxHeight: "75px",
                        maxWidth: "100px"
                    }}
                    id="large" alt="" className="img-responsive" />
            </div>
        );
    }

    actionFormatter = (cell, row) => {
        return (
            <div className="btn-action-group btn-group-md">
                <Button className="float-left" onClick={() => this.setEditData(row)}>
                    <i className="fa fa-pencil-square-o"></i> Edit
                </Button>
                <Button className="float-left" color="danger" onClick={() => this.setDeleteData(row)}>
                    <i className="fa fa-trash-o"></i> Delete
                </Button>
                {row.instock === "yes" ?
                    <Button color="warning" className="float-left margin-top-10" onClick={() => this.updateStockStatus("no", row.id)}>
                        <i className="fa fa-close"></i> Out of Stock
                </Button> :
                    <Button color="success" className="float-left margin-top-10" onClick={() => this.updateStockStatus("yes", row.id)}>
                        <i className="fa fa-check-circle"></i> In Stock
                </Button>
                }
            </div>
        );
    }

    toggleAddModal = (data) => {
        this.setState({ showAddModal: data, showAdd: data, showUpdate: false, editData: {} });
    }

    setEditData = (data) => {
        this.setState({
            editData: data,
            showAddModal: true,
            showAdd: false,
            showUpdate: true
        })
    }

    render() {

        const options = {
            page: 1,
            sizePerPage: 10,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: 'Prev',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            paginationPosition: 'bottom',
            hidePageListOnlyOnePage: true,
            hideSizePerPage: true,
            btnGroup: this.createCustomButtonGroup,
        };

        let { categoryData, showAddModal, showAdd, editData, showUpdate, showToast,
            message, toastType, productsData, showWarningModal } = this.state;

        let addProductProps = {
            showAddModal,
            showAdd,
            toggleAddModal: this.toggleAddModal,
            editData,
            showUpdate,
            categoryData,
            productsData,
            addProduct: this.addProduct,
            updateProduct: this.updateProduct
        }

        let confirmProps = {
            showAlert: showWarningModal,
            title: "Are you sure want to delete ?",
            message: "You will not be able to recover this !",
            closeAlert: this.toggleWarningModal,
            confirmAction: this.deleteProduct,
            confirmBtnText: "Delete",
            clearOnSave: false
        }

        let toastProps = { showToast, message, toastType };

        return (
            <div>
                <h1 className="text-center">Products</h1>
                <Loader showLoader={this.state.showLoader} />
                <ToastMessage {...toastProps} />
                <BootstrapTable pagination={true} data={productsData} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} options={options} search>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="name" thStyle={{ fontSize: "12px" }} dataSort={true} >Name</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='150' dataField="description" thStyle={{ fontSize: "12px" }} dataSort={true} >Description</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="category_name" thStyle={{ fontSize: "12px" }} dataSort={true} >Category</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="logo_path" thStyle={{ fontSize: "12px" }} dataSort={true} dataFormat={this.logoFormatter} >Logo</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='80' dataField="id" thStyle={{ fontSize: "12px" }} isKey={true} dataSort={false} dataFormat={this.actionFormatter}>Action</TableHeaderColumn>
                </BootstrapTable>

                <AddProducts {...addProductProps} />

                <WarningModal {...confirmProps} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
