import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Col, Row, Label, Input, FormGroup, Button, Alert } from "reactstrap";
import "./contact-us.css";
import helpers from '../../helpers';
import axios from "axios";
import Loader from "../Common/Loader";

const restURL = helpers.restURL + "dashboard_products.php";

export class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerName: "",
            customerEmail: "",
            message: "",
            customerNameError: "",
            messageError: "",
            requestBtnDisabled: true,
            showLoader: "hide",
            type: "",
            errorMessage: ""
        }
    }

    handleRequestFormChange = (e) => {

        let { name, value, pattern } = e.target;
        let errorField = name + `Error`;
        if (value === "") {
            this.setState({ [errorField]: e.target.placeholder + " is required !" });
        } else {
            if (typeof pattern !== 'undefined' && pattern !== "" && pattern !== null) {
                pattern = pattern.trim();
                let regex = new RegExp(helpers._pattern[pattern]);
                if (!regex.test(value)) {
                    this.setState({ [errorField]: "Enter valid " + e.target.placeholder });
                } else {
                    this.setState({ [errorField]: "" });
                }
            } else {
                this.setState({ [errorField]: "" });
            }
        }

        this.setState({ [name]: value });

        setTimeout(() => {
            this.validateForm();
        }, 100);
    }

    validateForm = () => {
        let requestBtnDisabled = true;
        let { customerName, customerNameError, customerEmail, customerEmailError, message, messageError } = this.state;
        if (customerName !== "" && customerEmail !== "" && message !== "" && customerNameError === "" && customerEmailError === "" && messageError === "") {
            requestBtnDisabled = false;
        } else {
            requestBtnDisabled = true;
        }
        this.setState({ requestBtnDisabled });
    }

    sendContactRequest = () => {
        let { customerName, message, customerEmail } = this.state;
        this.setState({
            showLoader: "show",
            type: "",
            errorMessage: ""
        });

        let data = {
            customer_name: customerName,
            customer_email: customerEmail,
            message: message,
            rest: "contactusrequest"
        };

        axios({
            method: "POST",
            url: restURL,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    type: "success",
                    errorMessage: "Contact request submitted successfully !. Our people will reach you soon.",
                    customerName: "", message: "", customerEmail: "",
                    requestBtnDisabled: true
                });
                setTimeout(() => {
                    this.setState({
                        type: "",
                        errorMessage: ""
                    });
                }, 10000)

            } else {
                this.setState({ type: "danger", errorMessage: "Failed to send Contact request. Please try again later." });
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {

        let { requestBtnDisabled, customerName, message, customerEmail, type, errorMessage } = this.state;

        return (
            <div className="contact-us-container container">
                <Loader showLoader={this.state.showLoader} />
                <Row>

                    {errorMessage !== "" ?
                        <Col sm="12" md="12" lg="12" xs="12">
                            <div className="error-message-block">
                                <Alert color={type} fade={false} style={{ marginBottom: "20px" }}>
                                    {errorMessage}
                                </Alert>
                            </div>
                        </Col> : <React.Fragment />}

                    <Col sm="6" md="6" lg="6" xs="12">
                        <h3>
                            <b>PATIL PARIMALA WORKS</b>
                        </h3>
                        <ul className="contact-info">
                            <li><i className="fa fa-map"></i> No. 82, 1st Main Road, <br />
                                    A V Road, Chamarajpet, Bangalore <br />
                                        Karnataka State, India - 560 018.</li>
                            <li><i className="fa fa-phone"></i> Phone: +91 80 26603713 / 22422479 / 26620205 </li>
                            <li><i className="fa fa-envelope"></i> Email: patilmkt711@gmail.com</li>
                            <li><i className="fa fa-globe"></i> <a rel="noreferrer" target="_blank" href="http://www.ullasincense.co.in/" >www.ullasincense.co.in</a></li>
                            <li><i className="fa fa-phone" > &nbsp;+91 9606074088</i>&nbsp;<br /></li>
                            <li><i className="fa fa-envelope">&nbsp; WhatsApp Business</i></li>
                        </ul>
                    </Col>
                    <Col sm="6" md="6" lg="6" xs="12">
                        <FormGroup>
                            <Label>Name</Label>
                            <Input type="text" pattern="plainstring" name="customerName" value={customerName} onChange={(e) => this.handleRequestFormChange(e)} placeholder="Name" />
                            <div className="errorMessage">{this.state.customerNameError}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Email ID</Label>
                            <Input type="text" pattern="email" name="customerEmail" value={customerEmail} onChange={(e) => this.handleRequestFormChange(e)} placeholder="Email ID" />
                            <div className="errorMessage">{this.state.customerEmailError}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Message</Label>
                            <Input type="textarea" name="message" value={message} onChange={(e) => this.handleRequestFormChange(e)} placeholder="Message" rows="5" cols="5" />
                            <div className="errorMessage">{this.state.messageError}</div>
                        </FormGroup>
                        <div className="contact-btn-container text-center">
                            <Button className="request-btn" color="info"
                                onClick={() => this.sendContactRequest()} disabled={requestBtnDisabled}>Submit</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
