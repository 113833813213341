import { routerReducer } from 'react-router-redux';
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import Home from "./Components/Home";
import { BrowserRouter, Switch } from "react-router-dom";
import { Route } from 'react-router';
import ExportAppHeader from "./Components/Header/ExportAppHeader";
import ProductDetails from "./Components/Products/ProductDetails";
import Footer from "./Components/Common/Footer";
import AboutUs from "./Components/Common/AboutUs";
import AdminHome from "./Components/Admin/AdminHome";
import PageNotFound from "./Components/Common/PageNotFound";
import Cart from "./Components/Common/Cart";
import ContactUs from "./Components/Common/ContactUs";
import AllProducts from "./Components/Products/AllProducts";
import reducer from "./reducers/index";

const store = createStore(combineReducers({
	reducer,
	routing: routerReducer
}), applyMiddleware(thunk));

const HomeComponents = ({ match }) => {
	return (
		<div>
			<ExportAppHeader />
			<Switch>
				<Route exact path="/" render={props => <Home {...props} />} />
				<Route path="/cart" render={props => <Cart {...props} />} />
				<Route path="/home" render={props => <Home {...props} />} />
				<Route path="/product-details" render={props => <ProductDetails {...props} />} />
				<Route path="/about-us" render={props => <AboutUs {...props} />} />
				<Route path="/contact-us" render={props => <ContactUs {...props} />} />
				<Route path="/all-products" render={props => <AllProducts {...props} />} />
				<Route path="/" render={props => <PageNotFound {...props} />} />
			</Switch>
			<Footer />
		</div>
	);
};


function App() {
	return (
		<div className="App">
			<Provider store={store}>
				<BrowserRouter basename="/">
					<Switch>
						<Route exact path="/admin-home" render={props => <AdminHome {...props} />} />
						{/* <Route exact path="/" render={props => <Home {...props} />} />
						<Route path="/home" render={props => <Home {...props} />} />
						<Route path="/product-details" render={props => <ProductDetails {...props} />} />
						<Route path="/about-us" render={props => <AboutUs {...props} />} />
						<Route path="/contact-us" render={props => <ContactUs {...props} />} />
						<Route path="/all-products" render={props => <AllProducts {...props} />} />
						<Route path="/" render={props => <PageNotFound {...props} />} /> */}
						<Route path="/" render={HomeComponents} />
					</Switch>
				</BrowserRouter>
			</Provider>
		</div >
	);
}

// function App() {
// 	return (
// 		<div className="App">
// 			<Provider store={store}>
// 				<BrowserRouter basename="/">
// 					{/* <ExportAppHeader /> */}
// 					<Switch>
// 						{/* <Route exact path="/" render={props => <Home {...props} />} />
// 						<Route path="/home" render={props => <Home {...props} />} />
// 						<Route path="/product-details" render={props => <ProductDetails {...props} />} />
// 						<Route path="/about-us" render={props => <AboutUs {...props} />} /> */}
// 						<Route exact path="/admin-home" render={props => <AdminHome {...props} />} />
// 						<Route path="/" render={HomeComponents} />

// 						{/* <Route path="/cart" render={props => <Cart {...props} />} />
// 						<Route path="/contact-us" render={props => <ContactUs {...props} />} />
// 						<Route path="/all-products" render={props => <AllProducts {...props} />} />
// 						<Route path="/" render={props => <PageNotFound {...props} />} /> */}
// 					</Switch>
// 					{/* <Footer /> */}
// 				</BrowserRouter>
// 			</Provider>
// 		</div >
// 	);
// }

export default App;
