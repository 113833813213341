import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, FormGroup, Input, Label, Col } from 'reactstrap';
import NewReleases from "./NewReleases";
import "./product-details.css";
import axios from "axios";
import helpers from "../../helpers";
import Loader from "../Common/Loader";
import { updateCart } from "../../actions/cartActions";
import { bindActionCreators } from "redux";
const restURL = helpers.restURL + "dashboard_products.php";

export class ProductDetails extends Component {

    constructor() {
        super();
        this.state = {
            qty: 1,
            description: "",
            // productData: {
            //     "id": "5",
            //     "name": "dirgin de montserrat",
            //     "description": "eee",
            //     "instock": "yes",
            //     "logo_path": "/var/www/html/exportapp/public/Assets/products/product_6.jpg",
            //     "category_id": "18",
            //     "category_name": "dirgin de montserrat"
            // },
            productData: {},
            showLoading: "hide",
            errorMessage: ""
        };
    }

    componentDidMount() {
        let search = this.props.location.search;
        search = search.split("?id=");
        if (search.length === 2) {
            if (search[1] !== "") {

            }
            this.getProductDetails(search[1]);
        }

        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100);

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {

            let search = nextProps.location.search;
            search = search.split("?id=");
            if (search.length === 2) {
                if (search[1] !== "") {

                }
                this.getProductDetails(search[1]);
            }

            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 100);
        }
    }

    getProductDetails = (productID) => {

        this.setState({ showLoading: "show", errorMessage: "Loading product details..." });
        let url = restURL + "?rest=singleproduct&product_id=" + productID;
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processProductData(data);
            } else {
                this.setState({ productData: {}, errorMessage: "No Data found !" })
            }
            this.setState({ showLoading: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    processProductData = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ productData: data[0], errorMessage: "" });
        } else {
            this.setState({ productData: {}, errorMessage: "No Data found !" })
        }
    }

    updateQty = (type) => {
        let { qty } = this.state;

        if (type === "inc") {
            qty++;

        } else if (type === "dec") {
            qty--;
        }

        if (qty < 1) qty = 1;
        this.setState({ qty });
    }

    handleChangeQty = (e) => {
        let { qty } = this.state;
        let { value } = e.target;

        value = parseInt(value);

        if (value !== "" && !isNaN(value) && value > 0) {
            qty = value;
        }

        this.setState({ qty });
    }


    getProductImage = (data) => {
        let imagePath = data.logo_path.split("/var/www/html/exportapp/public/");
        imagePath = imagePath[1];
        return (
            <img itemProp="image"
                src={imagePath}
                style={{ height: "300px", width: "350px" }}
                id="large" alt={data.name} className="img-responsive" />
        )
    }

    addToCart = () => {
        let { productData, qty, description } = this.state;
        this.setState({ showLoading: "show" }, () => {
            helpers.addTocart(productData, qty, description);
        });

        setTimeout(() => {
            this.props.updateCart();
            this.setState({ showLoading: "hide" })
        }, 500);
    }

    handleFormChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {

        let { qty, productData, errorMessage } = this.state;;

        return (
            <div>
                <Loader showLoader={this.state.showLoading} />
                {Object.keys(productData).length > 0 ?
                    <div>
                        <section className="listing-page-content page-content padding-top-10">
                            <div className="container">
                                <div className="product-cols row">
                                    <Col md="6" lg="6" sm="6" xs="12">

                                        <div id="main-image" className="main-image text-center" style={{ marginTop: "50px" }}>
                                            <a href="/Assets/products/product_10.jpg" target="_blank" className="MagicZoomPlus"
                                                title={productData.name} data-options="zoomCaption: bottom;">
                                                {this.getProductImage(productData)}
                                            </a>
                                            <div name="imagecaptiont" title="" id="imagecaptiont" className="imagecaptiont"></div>
                                        </div>

                                        {/* <div id="addl-images" className="addl-images">
                                        <div className="flexslider" role="region" aria-label="Carousel">
                                            <ul className="slides"></ul>
                                        </div>
                                    </div> */}

                                    </Col>

                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <div className="product-details">
                                            <h1 itemProp="name" className="page_headers" aria-label="productname" role="main">{productData.name}</h1>


                                            <div className="short-description" itemProp="description">{productData.name}</div>

                                            <div className="product-id"><strong>Category :</strong>
                                                <span id="product_id" itemProp="sku"> {productData.category_name}</span>
                                            </div>

                                            <div id="availabilityInfo" className="availabilityInfo">
                                                <span className="availability-header">Availability:</span>
                                                <span id="availability" className="product_availability availability-item">
                                                    {productData.instock === "yes" ? " In Stock." :
                                                        <span className="out-of-stock"> Out of Stock !</span>}
                                                </span>
                                            </div>

                                            <div className="extrafieldsBlock" />

                                            <div className="addToCartBlock">
                                                <div className="qtybox-addcart form-group">

                                                    <label>Quantity</label>
                                                    <label className="qty-input">
                                                        <input type="text" value={qty} className="form-control"
                                                            onChange={(e) => this.handleChangeQty(e)} />

                                                        <span className="qty-nav">
                                                            <button type="button" className="qty-up" onClick={() => this.updateQty("inc")}>+</button>
                                                            <button type="button" className="qty-down" onClick={() => this.updateQty("dec")}>-</button>
                                                        </span>
                                                    </label>
                                                    <div className="extrafieldsBlock" />

                                                    <FormGroup>
                                                        <Label>Description</Label>
                                                        <Input type="textarea" value={this.state.description}
                                                            placeholder="Enter Query or Message here"
                                                            name="description" cols="3" rows="3"
                                                            onChange={(e) => this.handleFormChange(e)} />
                                                    </FormGroup>

                                                    <Button className="btn btn-default btn-inverse btn-addcart" onClick={() => this.addToCart()}>
                                                        <i className="icon-basket"></i> I'm Intrested
                                            </Button>

                                                </div>
                                            </div>

                                            <div className="clear"></div>

                                            <div id="extend-offer"></div>

                                            <div className="clear"></div>

                                        </div>
                                    </Col>

                                    <Col md="12" lg="12" sm="12" xs="12">
                                        <h3 className="products-header">
                                            <span>Description</span>
                                        </h3>
                                        <div>
                                            <div className="item">
                                                <p>- {productData.description}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </section>

                        <NewReleases title="Similar Items" type="similaritems" categoryID={productData.category_id} />
                    </div>
                    :
                    <Col md="12" lg="12" sm="12" xs="12" style={{ padding: "40px 20px" }}>
                        <h4 className="text-center">{errorMessage}</h4>
                    </Col>}


            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateCart: updateCart }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
