import React, { PureComponent } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import helpers from '../../helpers';
import Loader from "../Common/Loader";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { updateCart } from "../../actions/cartActions";
import { bindActionCreators } from "redux";

export class QuickView extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            qty: 1,
            description: "",
            showLoading: "hide"
        }
    }

    getProductImage = () => {
        let { name, logo_path } = this.props;
        if (typeof logo_path !== 'undefined') {
            let imagePath = logo_path.split("/var/www/html/exportapp/public/");
            imagePath = imagePath[1];
            return (
                <a href={imagePath} target="_blank" rel="noreferrer">
                    <img src={imagePath} alt={name}
                        className="img-responsive" />
                </a>
            )
        }
    }

    handleFormChange = (e) => {
        let value = e.target.value;
        if (e.target.name === "qty" && parseInt(value) > 1000000) {
            value = 1000000;
        }
        this.setState({ [e.target.name]: value });
    }

    addToCart = (productData) => {
        let { qty, description } = this.state;
        if (qty === "" || parseInt(qty) < 0) {
            qty = 1;
        };

        this.setState({ showLoading: "show" }, () => {
            helpers.addTocart(productData, qty, description);
            this.props.hideQuickView();
        });

        setTimeout(() => {
            this.props.updateCart();
            this.setState({ showLoading: "hide" });
        }, 500);
    }

    redirectToDetails = (data) => {
        this.props.hideQuickView();
        this.props.history.push("/product-details?id=" + data.id);
    }

    render() {

        let { name, showQuickView, instock, description, category_name, productData } = this.props;

        return (
            <div>
                <Loader showLoader={this.state.showLoading} />
                <Modal size="lg" fade={false} isOpen={showQuickView}>
                    <ModalHeader toggle={this.props.hideQuickView} className="text-center">
                        <span style={{ fontSize: "18px" }} className="quick-view-product-header">Product- {name}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{ padding: "20px" }}>
                            <Col sm="6" md="6" lg="6" xs="12">
                                <div className="img">
                                    {this.getProductImage()}
                                </div>
                            </Col>
                            <Col sm="6" md="6" lg="6" xs="12">
                                <div className="product-content">
                                    <div className="name" onClick={() => this.redirectToDetails(productData)}>
                                        <h2>{name}</h2>
                                    </div>
                                    <p>Category : {category_name}</p>
                                    <p>Description : {description}</p>

                                    <div className="status">
                                        <span className="availability">
                                            <b>Availability:</b>  {instock === "yes" ? "In Stock." : "Out of Stock !"}
                                        </span>
                                    </div>

                                    <FormGroup style={{ marginTop: "10px" }}>
                                        <Label>Qty</Label>
                                        <Input type="number" value={this.state.qty} name="qty" onChange={(e) => this.handleFormChange(e)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Description</Label>
                                        <Input type="textarea" value={this.state.description} name="description" cols="3" rows="3" onChange={(e) => this.handleFormChange(e)} />
                                    </FormGroup>

                                    <div className="action" style={{ marginTop: "20px", textAlignLast: "center" }}>
                                        <Button color="info" onClick={() => this.addToCart(productData)}> I'm Intrested</Button>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ padding: "20px" }} />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateCart: updateCart }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickView));

