import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import helpers from '../../helpers';
import Loader from "../Common/Loader";
import { withRouter } from 'react-router-dom';
import "./cart.css";
import axios from "axios";
import { updateCart } from "../../actions/cartActions";
import { bindActionCreators } from "redux";

const restURL = helpers.restURL + "request.php";
export class Cart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cartItems: [],
            requestBtnDisabled: true,
            customerName: "",
            customerNameError: "",
            customerEmail: "",
            customerEmailError: "",
            customerPhone: "",
            customerPhoneError: "",
            errorMessage: "",
            showLoader: "hide",
            errorType: ""
        }
    }

    componentDidMount() {
        this.getCartItems();
    }

    componentWillReceiveProps() {
        this.getCartItems();
    }

    getCartItems = () => {
        let cartData = helpers.getLocalStorage('cartItems');
        if (typeof cartData !== 'undefined' && cartData !== null && cartData !== "") {
            try {
                let cartItems = JSON.parse(cartData);
                this.setState({ cartItems });
            } catch (e) {
                console.log("e", e);
            }
        } else {
            this.setState({ cartItems: [] });
        }
    }

    removeItemFromCart = (data) => {
        this.setState({ showLoader: "show" }, () => {
            let isRemoved = helpers.removeProductFromCart(data.uuid);
            if (isRemoved) {
                this.getCartItems();
            }
        });

        setTimeout(() => {
            this.props.updateCart();
            this.setState({ showLoader: "hide" });
        }, 1000);

    }

    redirect = (url) => {
        this.props.history.push("/" + url);
    }

    getProductImage = (data) => {
        let imagePath = data.logo_path.split("/var/www/html/exportapp/public/");
        imagePath = imagePath[1];
        return (
            <img src={imagePath} alt={data.name}
                className="img-responsive" style={{ width: "112px", height: "112px" }} />
        )
    }

    handleQtyFormChange = (data, e) => {
        let value = e.target.value;
        let { cartItems } = this.state;

        if (value !== "" && !isNaN(parseInt(value)) && parseInt(value) > 0) {
            for (let i = 0; i < cartItems.length; i++) {
                if (cartItems[i].uuid === data.uuid) {
                    cartItems[i].product_qty = value;
                    break;
                }
            }
        }
        this.setState({ cartItems });
    }

    handleQtyChange = (data, type) => {

        let qty = data.product_qty;
        let { cartItems } = this.state;

        if (type === "inc") {
            qty++;

        } else if (type === "dec") {
            qty--;
        }

        if (qty < 1) qty = 1;
        data.product_qty = qty;

        for (let i = 0; i < cartItems.length; i++) {
            if (cartItems[i].uuid === data.uuid) {
                cartItems[i] = data;
                break;
            }
        }

        this.setState({ cartItems });
    }

    handleRequestFormChange = (e) => {

        let { name, value, pattern } = e.target;
        let errorField = name + `Error`;
        if (value === "") {
            this.setState({ [errorField]: e.target.placeholder + " is required !" });
        } else {
            if (typeof pattern !== 'undefined' && pattern !== "" && pattern !== null) {
                pattern = pattern.trim();
                let regex = new RegExp(helpers._pattern[pattern]);
                if (!regex.test(value)) {
                    this.setState({ [errorField]: "Enter valid " + e.target.placeholder });
                } else {
                    this.setState({ [errorField]: "" });
                }
            }
        }

        this.setState({ [name]: value });

        setTimeout(() => {
            this.validateForm();
        }, 100);
    }

    validateForm = () => {
        let requestBtnDisabled = true;
        let { customerName, customerNameError, customerEmail, customerEmailError, customerPhone, customerPhoneError } = this.state;
        if (customerName !== "" && customerEmail !== "" && customerPhone !== "" && customerNameError === "" && customerEmailError === "" && customerPhoneError === "") {
            requestBtnDisabled = false;
        } else {
            requestBtnDisabled = true;
        }
        this.setState({ requestBtnDisabled });
    }

    redirectToDetails = (data) => {
        this.props.history.push("/product-details?id=" + data.id);
    }

    validateAndMakeRequest = () => {
        let { cartItems, customerName, customerEmail, customerPhone } = this.state;
        if (cartItems === null || cartItems.length === 0) {
            this.setState({ errorMessage: "Please Add Products to Send Request !", errorType: "danger" });
            return
        } else {
            this.setState({ errorMessage: "", errorType: "", showLoader: "show" });

            let products = [];

            for (let i = 0; i < cartItems.length; i++) {
                let singleItem = cartItems[i];
                let obj = {
                    product_id: parseInt(singleItem.product_id),
                    product_qty: parseInt(singleItem.product_qty),
                    product_description: singleItem.product_description,
                    productData: singleItem.productData
                }
                products = products.concat(obj);
            }

            let url = window.location.protocol + "//" + window.location.hostname + "/";

            let data = {
                rest: "addrequest",
                customer_name: customerName,
                customer_email: customerEmail,
                customer_phone: customerPhone,
                products,
                hostname: url
            }

            axios({
                method: "POST",
                url: restURL,
                data: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json"
            }).then(res => {
                if (res.data.success) {
                    let clear = helpers.clearCart();
                    if (clear) {
                        this.getCartItems();
                        this.clearState();
                    }
                    this.setState({ errorMessage: "Request sent successfully !", errorType: "success" });
                } else {
                    this.setState({ errorMessage: "Failed to Send Request", errorType: "danger" });
                }
                this.setState({ showLoader: "hide" });
            }).catch(error => {
                console.log(error);
            });
        }
    }

    clearState = () => {
        this.setState({
            requestBtnDisabled: true,
            customerName: "",
            customerNameError: "",
            customerEmail: "",
            customerEmailError: "",
            customerPhone: "",
            customerPhoneError: ""
        });
    }

    render() {

        let { cartItems, requestBtnDisabled, errorMessage, errorType, customerName, customerEmail, customerPhone } = this.state;

        return (
            <div id="modNewReleases">
                <Loader showLoader={this.state.showLoader} />
                <div className="container-fluid margin-top-20  cart-container">
                    <Row>
                        <Col sm="9" md="9" lg="9" xs="12" className="padding-0">
                            <div className="cart-products-section">
                                <h4 className="cart-products-header">
                                    <span>My Cart ({cartItems.length})</span>
                                </h4>

                                {errorMessage !== "" ?
                                    <div className="error-message-block">
                                        < Alert color={errorType} fade={false} style={{ marginBottom: "20px" }}>
                                            {errorMessage}
                                        </Alert>
                                    </div> : <React.Fragment />}

                                {cartItems.length === 0 ?
                                    <div className="padding-20-bottom">
                                        <h4 className="text-center">No Product(s) in your cart ..! </h4>
                                        <div className="text-center cursor-pointer" style={{ color: "#607D8B", paddingTop: "20px" }}>
                                            <span onClick={() => this.redirect("home")}>Click here to shop...</span>
                                        </div>
                                    </div>
                                    :
                                    cartItems.map((data, index) => (
                                        <Row key={index} className="cart-items">

                                            <Col sm="5" md="5" lg="2" xs="5">
                                                {this.getProductImage(data.productData)}
                                                <div className="cart-qty-container">

                                                    <Button className="qty-dec" onClick={() => this.handleQtyChange(data, "dec")} disabled={data.product_qty <= 1 ? true : false}>
                                                        <i className="fa fa-minus"></i>
                                                    </Button>

                                                    <Input type="text" value={data.product_qty}
                                                        onChange={(e) => this.handleQtyFormChange(data, e)} className="cart-qty-input" />

                                                    <Button className="qty-inc" onClick={() => this.handleQtyChange(data, "inc")}>
                                                        <i className="fa fa-plus"></i>
                                                    </Button>

                                                </div>
                                            </Col>

                                            <Col sm="7" md="7" lg="10" xs="7">
                                                <h5 className="cart-product-h5 cursor-pointer" onClick={() => this.redirectToDetails(data.productData)}>{data.productData.name}</h5>

                                                <h5 className="cart-product-h5 cart-items-dull-font font-size-14">Category : {data.productData.category_name}</h5>

                                                <p className="font-size-14">{data.productData.description}</p>

                                                <h5 className="cart-product-h5 font-size-14">Request Description : {data.product_description !== "" ? data.product_description : "-"}</h5>

                                                <span className="remove-btn" onClick={() => this.removeItemFromCart(data)}>Remove</span>
                                            </Col>

                                        </Row>
                                    ))
                                }
                            </div>
                        </Col>
                        <Col sm="3" md="3" lg="3" xs="12" className="margin-0 padding-0-10 checkout-container">
                            <div className="cart-products-section">
                                <h4 className="cart-products-header">
                                    <span>Request Details</span>
                                </h4>
                                <div className="padding-10">
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input type="text" pattern="plainstring" value={customerName} name="customerName" onChange={(e) => this.handleRequestFormChange(e)} placeholder="Name" />
                                        <div className="errorMessage">{this.state.customerNameError}</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Email Address</Label>
                                        <Input type="text" pattern="email" value={customerEmail} name="customerEmail" onChange={(e) => this.handleRequestFormChange(e)} placeholder="Email Address" />
                                        <div className="errorMessage">{this.state.customerEmailError}</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Phone Number</Label>
                                        <Input type="text" pattern="phone" value={customerPhone} name="customerPhone" onChange={(e) => this.handleRequestFormChange(e)} placeholder="Phone Number" />
                                        <div className="errorMessage">{this.state.customerPhoneError}</div>
                                    </FormGroup>
                                    <Button className="request-btn"
                                        onClick={() => this.validateAndMakeRequest()}

                                        color="info" disabled={requestBtnDisabled}>
                                        <i className="fa fa-send-o"></i> Request</Button>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateCart: updateCart }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));

