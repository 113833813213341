import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import "./product.css";

export class AddProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            btnDisabled: true,
            name: "",
            description: "",
            id: "",
            nameError: "",
            descriptionError: "",
            originalName: "",
            category_idError: "",
            category_id: "",
            instock: false,
            instockError: "",
            logo: "",
            logoError: ""
        };
    }

    componentDidMount() {
        let { editData } = this.props;
        this.setEditData(editData);
    }

    componentWillReceiveProps(nextProps) {
        let { editData } = nextProps;
        this.setEditData(editData);
    }

    setEditData = (data) => {
        if (Object.keys(data).length > 0) {
            let { name, description, id, category_id, instock } = data;
            instock = instock === 'yes' ? true : false;
            this.setState({
                name, description, id, category_id, instock,
                nameError: "",
                descriptionError: "", category_idError: "", instockError: "", logoError: "",
                originalName: name
            });
        } else {
            this.setState({
                originalName: "",
                name: "",
                description: "",
                id: "",
                nameError: "",
                descriptionError: "",
                category_idError: "",
                category_id: "",
                instock: false,
                instockError: "",
                logo: "",
                logoError: ""
            });
        }
    }

    handleFormChange = (e) => {
        let { originalName } = this.state;
        let value = e.target.value;
        if (value === "") {
            this.setState({ [e.target.name]: value, [e.target.name + "Error"]: [e.target.placeholder + " is required"] });
        } else {
            this.setState({ [e.target.name]: value, [e.target.name + "Error"]: "" });
        }

        if (e.target.name === "name") {
            let { productsData } = this.props;
            let duplicateProductFound = false;
            if (productsData.length > 0) {
                for (let i = 0; i < productsData.length; i++) {
                    if (productsData[i].name.toLowerCase() === value.toLowerCase().trim() && originalName.toLowerCase() !== value.toLowerCase().trim()) {
                        duplicateProductFound = true;
                        break;
                    }
                }
                if (value !== "") {
                    if (duplicateProductFound) {
                        this.setState({ [e.target.name + "Error"]: "Product Name is already present, please choose different name." });
                    } else {
                        this.setState({ [e.target.name + "Error"]: "" });
                    }
                }
            }
        }

        setTimeout(() => {
            this.validateForm();
        }, 100);
    }

    handleInStockChange = () => {
        this.setState({ instock: !this.state.instock });
        setTimeout(() => {
            this.validateForm();
        }, 100);
    }

    handleLogoChange = (event) => {
        let logo = event.target.files[0];
        if (typeof logo === 'undefined' || logo === "") {
            logo = "";
            if (!this.props.showUpdate) {
                this.setState({ logo, "logoError": "Logo is required" });
            } else {
                this.setState({ logo, "logoError": "" });
            }
        } else {
            this.setState({ logo, "logoError": "" });
        }
        setTimeout(() => {
            this.validateForm();
        }, 100);
    }

    validateForm() {
        let { name, description, descriptionError, nameError, category_idError,
            category_id, instock, instockError, logo, logoError } = this.state;
        if (this.props.showAdd) {
            this.setState({
                btnDisabled: description !== "" && name !== "" && descriptionError === "" &&
                    nameError === "" && category_id !== "" && instock !== "" && logo !== "" &&
                    instockError === "" && category_idError === "" && logoError === "" ? false : true
            });
        } else if (this.props.showUpdate) {
            this.setState({
                btnDisabled: description !== "" && name !== "" && descriptionError === "" &&
                    nameError === "" && category_id !== "" && instock !== "" &&
                    instockError === "" && category_idError === "" ? false : true
            });
        }

    }

    saveProduct = () => {
        let { name, description, category_id, instock, logo } = this.state;
        if (instock === true) {
            instock = "yes"
        } else {
            instock = "no"
        }

        let productData = new FormData();
        productData.append("category_id", category_id);
        productData.append("description", description);
        productData.append("instock", instock);
        productData.append("name", name);
        productData.append("product_image", logo);
        productData.append("rest", "addproduct");
        this.props.addProduct(productData);
    }

    updateProduct = () => {
        let { name, description, category_id, instock, logo, id } = this.state;

        if (instock === true) {
            instock = "yes"
        } else {
            instock = "no"
        }

        if (logo !== "") {
            let productData = new FormData();
            productData.append("category_id", category_id);
            productData.append("description", description);
            productData.append("instock", instock);
            productData.append("name", name);
            productData.append("product_image", logo);
            productData.append("product_id", id);
            productData.append("rest", "updateproductwithfile");
            this.props.updateProduct(productData, true);
        } else {
            let data = {
                product_id: id,
                name,
                description,
                category_id,
                instock,
                rest: "updateproductwithoutfile"
            }
            this.props.updateProduct(data, false);
        }
    }

    render() {
        return (
            <div>
                <Modal size="md" fade={false} isOpen={this.props.showAddModal} backdrop="static">
                    <ModalHeader>
                        <span style={{ fontSize: "18px" }}>Manage Product</span>
                    </ModalHeader>

                    <ModalBody className="padding-20">
                        <Row>
                            <Col sm="12" md="12" lg="12" xs="12">
                                <FormGroup>
                                    <Label >Name</Label>
                                    <Input value={this.state.name} name="name" onChange={(e) => this.handleFormChange(e)} type="text" placeholder="Name" />
                                    <div className="errorMessage">{this.state.nameError}</div>
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="12" lg="12" xs="12">
                                <FormGroup>
                                    <Label >Description</Label>
                                    <Input value={this.state.description} name="description" rows="5" cols="5" onChange={(e) => this.handleFormChange(e)} type="textarea" placeholder="Description" />
                                    <div className="errorMessage">{this.state.descriptionError}</div>
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="12" lg="12" xs="12">
                                <FormGroup>
                                    <Label>Category</Label>
                                    <Input value={this.state.category_id} name="category_id" onChange={(e) => this.handleFormChange(e)}
                                        type="select" >
                                        <option value="" disabled>---select----</option>
                                        {this.props.categoryData.map((data, index) => (
                                            <option value={data.id} key={index}>{data.category_name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.category_idError}</div>
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="12" lg="12" xs="12">
                                <FormGroup>
                                    <Label>Image</Label>
                                    <Input name="logo" onChange={(e) => this.handleLogoChange(e)} type="file" />
                                    <div className="errorMessage">{this.state.logoError}</div>
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="12" lg="12" xs="12">
                                <FormGroup>
                                    <Label>In Stock</Label>
                                    <Input checked={this.state.instock} className="margin-left-10" name="instock" onChange={() => this.handleInStockChange()} type="checkbox" />
                                    <div className="errorMessage">{this.state.instockError}</div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Col sm="12" md="12" lg="12" xs="12">
                            <FormGroup className="text-right">
                                {this.props.showAdd === true ?
                                    <Button size="md" color="success" className="margin-right-5" disabled={this.state.btnDisabled} onClick={() => this.saveProduct()}>Save</Button> :
                                    this.props.showUpdate === true ?
                                        <Button size="md" color="success" className="margin-right-5" disabled={this.state.btnDisabled} onClick={() => this.updateProduct()}>Update</Button>
                                        : <React.Fragment />}

                                <Button size="md" color="secondary" onClick={() => this.props.toggleAddModal(false)}>Close</Button>
                            </FormGroup>
                        </Col>

                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AddProducts)
