import React, { PureComponent } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ToastMessage extends PureComponent {

    componentWillReceiveProps(nextProps) {
        let { showToast, message, toastType } = nextProps;

        if (this.props.showToast !== showToast && showToast) {
            if (toastType === 'success') {
                toast.success(message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    // progress: undefined,
                });
            } else if (toastType === 'error') {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    // progress: undefined,
                });
            }
        }
    }


    render() {
        return (
            <ToastContainer />
        )
    }
}
