import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Footer extends Component {
    render() {

        return (
            <div>
                {/* <footer className="site-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="visible-xs extrapages-shower">
                                    <a href="#extrapages" role="button" data-toggle="collapse" className="collapsed">Pages</a>
                                </div>
                                <div id="FRAME_LINKS">
                                    <ul id="extrapages" className="list-unstyled row extrapages">
                                        <li className="col-sm-4"><a href="Order-Help_ep_44-1.html" target="_self"
                                            className="menu-bottom">Order Help</a></li>
                                        <li className="col-sm-4"><a href="FAQ_ep_45-1.html" target="_self" className="menu-bottom">FAQ</a>
                                        </li>
                                        <li className="col-sm-4"><a href="Shipping-Handling_ep_46-1.html" target="_self"
                                            className="menu-bottom">Shipping & Handling</a></li>
                                        <li className="col-sm-4"><a href="About_ep_47-1.html" target="_self"
                                            className="menu-bottom">About</a></li>
                                        <li className="col-sm-4"><a href="Terms-and-Conditions_ep_2-1.html" target="_self"
                                            className="menu-bottom">Terms and Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}

                <div className="copyright">
                    <div className="container">
                        <p> Copyright<a rel="nofollow noreferrer" href="https://ullasincense.co.in/"
                            target="_blank"> @ullasincense </a> All Rights Reserved.
                        </p>
                    </div>
                </div>

                {/* <aside id="qv-modal" className="qv qv-modal modal" tabIndex="-1" role="dialog" data-catalogid="" data-price="0"
                    data-sku="" data-backdrop="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-vcenter">
                            <div className="qv-loader">
                                <span className="mailing-btn-load icon-spin2 animate-spin"></span>
                            </div>
                            <div className="product-item modal-content">
                                <button type="button" className="qv-close close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span></button>
                                <iframe id="qvIframe" frameBorder="0"></iframe>
                            </div>
                        </div>
                    </div>
                </aside> */}

            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
