import React, { PureComponent } from 'react';
import axios from "axios";
import helpers from "../../../helpers";
import Loader from "../../Common/Loader";
import ToastMessage from "../../Common/ToastMessage";
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import { Button, FormGroup, Input } from "reactstrap";
const restURL = helpers.restURL + "request.php";

export default class Requests extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            requestData: [],
            editData: {},
            showLoader: "hide",
            showWarningModal: false,
            showToast: false,
            message: "",
            toastType: "",
            searchFilter: "all"
        }
    }

    componentDidMount() {
        this.getRequests(this.state.searchFilter);
    }

    getRequests = (searchFilter) => {
        this.setState({ showLoader: "show" });
        let url = restURL + "?rest=getrequests&filter=" + searchFilter;
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processRequestData(data);
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    handleFilterChange = (e) => {
        let searchFilter = e.target.value;
        this.setState({ searchFilter });
        this.getRequests(searchFilter);
    }

    processRequestData = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ requestData: data });
        } else {
            this.setState({ requestData: [] })
        }
    }

    logoFormatter = (cell, row) => {
        cell = cell.split("/var/www/html/exportapp/public/");
        cell = cell[1];
        return (
            <div>
                <img itemProp="image"
                    src={cell}
                    style={{ height: "50%", width: "50%" }}
                    id="large" alt="" className="img-responsive" />
            </div>
        );
    }

    showToast = (message, toastType) => {
        this.setState({
            showToast: true,
            message: message,
            toastType: toastType
        });
        setTimeout(() => {
            this.setState({ showToast: false })
        }, 500);
    }

    updateRequestReadStatus = (status, requestID) => {
        this.setState({
            showLoader: "show"
        });

        let data = {
            filter: this.state.searchFilter,
            status: status,
            request_id: requestID,
            rest: "updaterequestreadstatus"
        }

        axios({
            method: "PUT",
            url: restURL,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processRequestData(data);
                this.showToast("Status Updated successfully !", "success");
            } else {
                this.showToast("Failed to update Status", "error");
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    actionFormatter = (cell, row) => {
        return (
            <div className="btn-action-group btn-group-md">
                {row.read_status === 'n' ?
                    <Button className="float-left" color="success" onClick={() => this.updateRequestReadStatus("y", row.id)}>
                        <i className="fa fa-pencil-square-o"></i> Read
                </Button> :
                    row.read_status === 'y' ?
                        <Button className="float-left" color="danger" onClick={() => this.updateRequestReadStatus("n", row.id)}>
                            <i className="fa fa-trash-o"></i> UnRead
                </Button> : <React.Fragment />}
            </div>
        );
    }


    createCustomButtonGroup = (props) => {
        return (
            <ButtonGroup sizeClass='btn-group-md'>
                <FormGroup>
                    <Input type="select" value={this.state.searchFilter} onChange={(e) => this.handleFilterChange(e)}>
                        <option value="" disabled>---select category---</option>
                        <option value="all">All</option>
                        <option value="read">Read</option>
                        <option value="pending">Pending</option>
                    </Input>
                </FormGroup>
            </ButtonGroup>
        );
    }


    render() {

        const options = {
            page: 1,
            sizePerPage: 10,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: 'Prev',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            paginationPosition: 'bottom',
            hidePageListOnlyOnePage: true,
            hideSizePerPage: true,
            btnGroup: this.createCustomButtonGroup,
        };


        let { requestData, showToast, message, toastType } = this.state;
        let toastProps = { showToast, message, toastType };
        return (
            <div>
                <Loader showLoader={this.state.showLoader} />
                <ToastMessage {...toastProps} />

                <BootstrapTable pagination={true} data={requestData} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} options={options} search>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="name" thStyle={{ fontSize: "12px" }} dataSort={true} >Name</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='150' dataField="description" thStyle={{ fontSize: "12px" }} dataSort={true} >Description</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="category_name" thStyle={{ fontSize: "12px" }} dataSort={true} >Category</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="logo_path" thStyle={{ fontSize: "12px" }} dataSort={true} dataFormat={this.logoFormatter} >Image</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="customer_name" thStyle={{ fontSize: "12px" }} dataSort={true} >Customer Name</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="customer_email" thStyle={{ fontSize: "12px" }} dataSort={true} >Customer Email</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="customer_phone" thStyle={{ fontSize: "12px" }} dataSort={true} >Customer Phone</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="product_qty" thStyle={{ fontSize: "12px" }} dataSort={true} >Qty Requested</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='100' dataField="product_description" thStyle={{ fontSize: "12px" }} dataSort={true} >Customer Description</TableHeaderColumn>
                    <TableHeaderColumn dataAlign='left' width='80' dataField="id" thStyle={{ fontSize: "12px" }} isKey={true} dataSort={false} dataFormat={this.actionFormatter}>Action</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
}
