var CryptoJS = require("crypto-js");
// let ENC_DEC_SECRET_KEY = 'htc56@bqz90e!qr78$nm148w';

export function encrypt(data) {
    let processedData = CryptoJS.AES.encrypt(data, "exportApp2021@bqz90e!qr78$nm148w");
    processedData = processedData.toString();
    return processedData;
}

export function decrypt(data) {
    let processedData;
    try {
        processedData = CryptoJS.AES.decrypt(data, "exportApp2021@bqz90e!qr78$nm148w")
        processedData = processedData.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        window.sessionStorage.clear();
        window.localStorage.clear();
        let cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            let cookieValues = cookies[i].split("=");
            document.cookie = cookieValues[0] + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
        }
    }
    return processedData;
}

export function hash(key) {
    key = CryptoJS.SHA256(key, "htc56@bqz90e!qr78$nm148w");
    return key.toString();
}