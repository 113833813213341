import React, { PureComponent } from 'react'
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core'
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

export default class Loader extends PureComponent {
    render() {
        return (
            <div className={"pageLoader " + this.props.showLoader}>
                <div className="loader">
                    <PulseLoader
                        css={override}
                        sizeUnit={"px"}
                        size={20}
                        color={'#00bcd4'}
                        loading={true}
                        margin={8}
                    />
                </div>
                <div style={{ top: "50%", position: "fixed", textAlign: "center", width: "100%" }}>
                    <h3 style={{ color: "inherit" }}>{typeof this.props.loadingText !== 'undefined' && this.props.loadingText !== null && this.props.loadingText !== "" ? this.props.loadingText : ""}</h3>
                </div>
            </div>
        )
    }
}


