import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col, Row } from 'reactstrap';

export class AddNewCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            btnDisabled: true,
            name: "",
            description: "",
            id: "",
            nameError: "",
            descriptionError: "",
            originalName: ""
        };
    }

    componentDidMount() {
        let { editData } = this.props;
        this.setEditData(editData);
    }

    componentWillReceiveProps(nextProps) {
        let { editData } = nextProps;
        this.setEditData(editData);
    }

    setEditData = (data) => {
        if (Object.keys(data).length > 0) {
            let { category_name, description, id } = data;
            this.setState({
                name: category_name,
                description,
                id,
                nameError: "",
                descriptionError: "", originalName: category_name
            });
        } else {
            this.setState({
                originalName: "",
                name: "",
                description: "",
                id: "",
                nameError: "",
                descriptionError: ""
            });
        }
    }

    handleFormChange = (e) => {
        let { originalName } = this.state;
        let value = e.target.value;
        if (value === "") {
            this.setState({ [e.target.name]: value, [e.target.name + "Error"]: [e.target.placeholder + " is required"] });
        } else {
            this.setState({ [e.target.name]: value, [e.target.name + "Error"]: "" });
        }

        if (e.target.name === "name") {
            let { categoryData } = this.props;
            let duplicateCategoryFound = false;
            if (categoryData.length > 0) {
                for (let i = 0; i < categoryData.length; i++) {
                    if (categoryData[i].category_name.toLowerCase() === value.toLowerCase().trim() && originalName.toLowerCase() !== value.toLowerCase().trim()) {
                        duplicateCategoryFound = true;
                        break;
                    }
                }
                if (value !== "") {
                    if (duplicateCategoryFound) {
                        this.setState({ [e.target.name + "Error"]: "Category Name is already present, please choose different name." });
                    } else {
                        this.setState({ [e.target.name + "Error"]: "" });
                    }
                }
            }
        }

        setTimeout(() => {
            this.validateForm();
        }, 100);
    }


    validateForm() {
        let { name, description, descriptionError, nameError } = this.state;
        this.setState({ btnDisabled: description !== "" && name !== "" && descriptionError === "" && nameError === "" ? false : true })
    }

    saveCategory = () => {
        let { name, description } = this.state;
        let data = {
            rest: "addcategory",
            name,
            description,
        }
        this.props.addNewCategory(data);
    }

    updateCategory = () => {
        let { name, description, id } = this.state;
        let data = {
            name, description,
            category_id: id,
            rest: "updatecategory"
        }
        this.props.updateCategory(data);
    }


    render() {
        return (
            <div>
                <Modal size="md" fade={false} isOpen={this.props.showAddModal} backdrop="static">
                    <ModalHeader>
                        <span style={{ fontSize: "18px" }}>Manage Category</span>
                    </ModalHeader>

                    <ModalBody className="padding-20">
                        <Row>
                            <Col sm="12" md="12" lg="12" xs="12">
                                <FormGroup>
                                    <Label >Name</Label>
                                    <Input value={this.state.name} name="name" onChange={(e) => this.handleFormChange(e)} type="text" placeholder="Name" />
                                    <div className="errorMessage">{this.state.nameError}</div>
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="12" lg="12" xs="12">
                                <FormGroup>
                                    <Label >Description</Label>
                                    <Input value={this.state.description} name="description" onChange={(e) => this.handleFormChange(e)} type="text" placeholder="Description" />
                                    <div className="errorMessage">{this.state.descriptionError}</div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Col sm="12" md="12" lg="12" xs="12">
                            <FormGroup className="text-right">
                                {this.props.showAdd === true ?
                                    <Button size="md" color="success" className="margin-right-5" disabled={this.state.btnDisabled} onClick={() => this.saveCategory()}>Save</Button> :
                                    this.props.showUpdate === true ?
                                        <Button size="md" color="success" className="margin-right-5" disabled={this.state.btnDisabled} onClick={() => this.updateCategory()}>Update</Button>
                                        : <React.Fragment />}

                                <Button size="md" color="secondary" onClick={() => this.props.toggleAddModal(false)}>Close</Button>
                            </FormGroup>
                        </Col>

                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCategory)
