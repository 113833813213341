import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Row, Col } from "reactstrap";
import "./product-details.css";
import axios from "axios";
import helpers from "../../helpers";
import Loader from "../Common/Loader";
import { updateCart } from "../../actions/cartActions";
import { bindActionCreators } from "redux";

const restURL = helpers.restURL + "dashboard_products.php";
const categoryRestURL = helpers.restURL + "admin/category.php";

export class AllProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // allProducts: [{ "id": "5", "name": "dirgin de montserrat", "description": "eee", "instock": "yes", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_6.jpg", "category_id": "18", "category_name": "dirgin de montserrat" }, { "id": "4", "name": "Gardenia", "description": "Gardenia", "instock": "yes", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_7.jpg", "category_id": "21", "category_name": "Sandal" }, { "id": "3", "name": "BLACK NAGCHAMPA", "description": "BLACK NAGCHAMPA", "instock": "yes", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_3.jpg", "category_id": "1", "category_name": "Sandal" }, { "id": "2", "name": "CANNABIS", "description": "CANNABIS", "instock": "no", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_4.jpg", "category_id": "1", "category_name": "Sandal" }, { "id": "1", "name": "CLOVE", "description": "CLOVE", "instock": "no", "logo_path": "/var/www/html/exportapp/public/Assets/products/product_5.jpg", "category_id": "1", "category_name": "Sandal" }],
            allProducts: [],
            errorMessage: "Loading...",
            productData: {},
            showQuickView: false,
            showLoading: "hide",
            limit: 20,
            offset: 0,
            categoryID: "",
            categoryData: [],
            totalProductsCount: 0,
            searchType: ""
        }
    }

    componentDidMount() {
        this.getCategories();
        let search = this.props.location.search;
        this.setState({
            allProducts: [],
            errorMessage: "Loading...",
            limit: 20,
            offset: 0,
            totalProductsCount: 0,
        }, () => {
            this.processURLandGetData(search);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            this.setState({
                allProducts: [],
                errorMessage: "Loading...",
                limit: 20,
                offset: 0,
                totalProductsCount: 0,
            }, () => {
                this.processURLandGetData(nextProps.location.search);
            });
        }
    }

    processURLandGetData = (search) => {
        if (search.includes("category&category_id")) {
            let categoryIDSplit = search.split("&category_id=");
            if (categoryIDSplit.length === 2) {
                let categoryID = categoryIDSplit[1];
                let typeSplit = categoryIDSplit[0].split("type=");

                this.setState({
                    categoryID,
                    searchType: typeSplit[1]
                }, () => {
                    this.getAllProducts(typeSplit[1]);
                });
            }
        } else {
            search = search.split("type=");
            if (search.length === 2) {
                if (search[1] !== "") {
                    this.setState({
                        categoryID: "",
                        searchType: search[1]
                    }, () => {
                        this.getAllProducts(search[1]);
                    });
                }
            }
        }

        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100);
    }

    getCategories = () => {
        this.setState({ showLoader: "show" });
        let url = categoryRestURL + "?rest=getcategories";
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processCategoryData(data);
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    processCategoryData = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ categoryData: data });
        } else {
            this.setState({ categoryData: [] })
        }
    }

    getAllProducts = (type) => {
        let { limit, offset, categoryID } = this.state;

        let data = {
            type: type,
            rest: "allproducts",
            limit: limit,
            offset: offset,
            category_id: categoryID
        };

        this.setState({ showLoader: "show" });
        axios({
            method: "POST",
            url: restURL,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processAllProducts(data);
            } else {
                this.setState({ allProducts: [], totalProductsCount: 0, errorMessage: "Failed to get New Release items. Please try again later." })
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    processAllProducts = (data) => {
        let { allProducts } = this.state;
        if (data !== null && data.products.length > 0) {
            allProducts = allProducts.concat(data.products);
            this.setState({ allProducts, totalProductsCount: data.count, errorMessage: "" });
        } else {
            this.setState({ allProducts: [], totalProductsCount: 0, errorMessage: "No Products found !" });
        }
    }

    getProductImage = (data) => {
        let imagePath = data.logo_path.split("/var/www/html/exportapp/public/");
        imagePath = imagePath[1];
        return (
            <img src={imagePath} alt={data.name}
                className="img-responsive" style={{ width: "293px", height: "191px" }} />
        )
    }

    showProductDetails = (data) => {
        this.setState({ showQuickView: true, productData: data });
    }

    hideQuickView = () => {
        this.setState({ showQuickView: false, productData: {} });
    }

    addToCart = (productData) => {
        this.setState({ showLoading: "show" }, () => {
            let qty = 1, description = "";
            helpers.addTocart(productData, qty, description);
        });

        setTimeout(() => {
            this.props.updateCart();
            this.setState({ showLoading: "hide" });
        }, 500);
    }

    redirectToDetails = (data) => {
        this.props.history.push("/product-details?id=" + data.id);
    }

    getNextBatchResults = () => {
        let { limit, offset, categoryID, searchType } = this.state;
        offset = offset + limit;

        this.setState({ offset }, () => {
            this.getAllProducts(searchType);
        });
    }

    render() {

        let { allProducts, totalProductsCount } = this.state;


        return (
            <div className="home-page-content">
                <Loader showLoader={this.state.showLoading} />
                <div id="modNewReleases" className="products-section">
                    <h3 className="products-header text-center">
                        <span>All Products</span>
                    </h3>

                    <div className="container-fluid">
                        <Row>
                            {allProducts.map((data, index) => (
                                <Col sm="3" md="3" lg="3" xs="6" className="padding-top-10" key={index}>
                                    <div className="product-item" data-animation="fadeInUp" data-timeout="500">
                                        <div className="img cursor-pointer">
                                            {this.getProductImage(data)}
                                            {/* <button className="quickview" onClick={() => this.showProductDetails(data)}>Quick View</button> */}
                                        </div>
                                        <div className="product-content text-center">
                                            <div className="name cursor-pointer" onClick={() => this.redirectToDetails(data)}>
                                                <span>{data.name}</span>
                                            </div>

                                            <div className="status">
                                                <span className="availability">
                                                    {data.instock === "yes" ? "In Stock." :
                                                        <span className="out-of-stock">Out of Stock !</span>}
                                                </span>
                                            </div>

                                            <div className="action">
                                                <Button onClick={() => this.addToCart(data)} className="add-to-cart btn btn-default btn-inverse">
                                                    I'm Intrested</Button>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <h5 className="text-center load-more-btn">{this.state.errorMessage}</h5>

                        {allProducts.length > 0 && totalProductsCount > 0 && allProducts.length !== totalProductsCount ?
                            <h6 className="text-center load-more-btn cursor-pointer" onClick={() => this.getNextBatchResults()}>Load More...</h6>
                            : <React.Fragment />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateCart: updateCart }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AllProducts)
