import React, { Component } from 'react'
import { connect } from 'react-redux'
import "./menu.css";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import helpers from "../../helpers";
const categoryRestURL = helpers.restURL + "admin/category.php";

export class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryData: []
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories = () => {
        this.setState({ showLoader: "show" });
        let url = categoryRestURL + "?rest=getcategories";
        axios({
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.processCategoryData(data);
            }
            this.setState({ showLoader: "hide" });
        }).catch(error => {
            console.log(error);
        });
    }

    processCategoryData = (data) => {
        if (data !== null && data.length > 0) {
            this.setState({ categoryData: data });
        } else {
            this.setState({ categoryData: [] })
        }
    }

    redirect = (url) => {
        this.props.history.push("/" + url);
    }

    render() {

        let { categoryData } = this.state;

        return (
            <div>
                <section id="category-navbar" className="navbar-wrapper hidden-xs sticky-header">
                    <div className="container">
                        <nav className="navbar navbar-inverse navbar-static-top">

                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
                                    aria-expanded="false" aria-controls="navbar">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>

                            <div id="navbar" className="navbar-collapse collapse">
                                <ul id="categories" className="nav navbar-nav">
                                    <li className="">
                                        <span onClick={() => this.redirect("all-products?type=products")}>All Products</span>
                                    </li>
                                    <li className="dropdown">
                                        <span data-toggle="dropdown">Shop By Category</span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <span onClick={() => this.redirect("all-products?type=category&category_id=all")}>All</span>
                                            </li>
                                            {categoryData.map((data, index) => (
                                                <li key={index}>
                                                    <span onClick={() => this.redirect("all-products?type=category&category_id=" + data.id)}>{data.category_name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li className="dropdown">
                                        <span data-toggle="dropdown" onClick={() => this.redirect("all-products?type=new")}>New</span>
                                    </li>
                                </ul>
                            </div>

                        </nav>
                    </div>
                </section>

                <nav id="mobile-menu" className="mobile-menu">
                    <div className="mobile-menu-inner">
                        <div className="mobile-menu-close">
                            <a href="#"></a>
                        </div>
                        <div className="mobile-menu-widget">
                            <h3>Menu Links</h3>
                            <div id="mobile-menulinks"></div>
                        </div>
                        <div className="mobile-menu-widget">
                            <h3>Categories</h3>
                            <div id="mobile-categories">
                                <ul>
                                    <li className="dropdown">
                                        <span onClick={() => this.redirect("all-products?type=products")}>Shop By Products</span>
                                    </li>
                                    <li className="dropdown">
                                        <span data-toggle="dropdown">Shop By Category</span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <span onClick={() => this.redirect("all-products?type=category&category_id=all")}>All</span>
                                            </li>
                                            {categoryData.map((data, index) => (
                                                <li key={index}>
                                                    <span onClick={() => this.redirect("all-products?type=category&category_id=" + data.id)}>{data.category_name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li className="dropdown">
                                        <span data-toggle="dropdown" onClick={() => this.redirect("all-products?type=new")}>New</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
