import { encrypt, decrypt } from "./crypto";
import { v4 as uuidv4 } from 'uuid';
var dateFormat = require('dateformat');


const helpers = {
    setCookie: function (cname, cvalue, exdays) {
        cvalue = typeof cvalue === 'undefined' ? '' : cvalue;
        let data = encrypt(cvalue.toString());
        document.cookie = cname + "=" + data + ";path=/";
        // document.cookie = cname + "=" + cvalue + ";" + ";path=/";
    },
    getCookie: function (cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                let data = c.substring(name.length, c.length);
                data = decrypt(data);
                return data;
            }
        }
        return "";
    },
    setSessionStorage: function (keyName, data) {
        let dataTobeStored = encrypt(data);
        window.sessionStorage.setItem([keyName], dataTobeStored);
    },
    getSessionStorage: function (keyName) {
        let data = window.sessionStorage.getItem([keyName]);
        if (data !== null) {
            data = decrypt(data);
        }
        return data;
    },
    setLocalStorage: function (keyName, data) {
        let dataTobeStored = encrypt(data);
        window.localStorage.setItem([keyName], dataTobeStored);
    },
    getLocalStorage: function (keyName) {
        let data = window.localStorage.getItem([keyName]);
        if (data !== null) {
            data = decrypt(data);
        }
        return data;
    },
    restURL: window.location.hostname === "localhost" ? "http://localhost/export-service/" : "export-service/",
    getTimeWithoutFormatter: (time) => {
        if (typeof time === 'undefined' || time === null || time === 0 || time === "") {
            time = "";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
        }
        return time;
    }, getTime: function (time) {
        if (typeof time === 'undefined' || time === null || time === 0) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "dd-mm-yyyy hh:MM:ss:l TT");
        }
        return time;
    }, getDateTime: function (time) {
        if (typeof time === 'undefined' || time === null || time === 0) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "dd-mm-yyyy hh:MM:ss");
        }
        return time;
    }, deleteCookie: function (cname) {
        document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
    }, addTocart: function (productData, qty, description) {
        let cartItems = [];
        let cartData = helpers.getLocalStorage('cartItems');
        if (typeof cartData !== 'undefined' && cartData !== null && cartData !== "") {
            try {
                cartItems = JSON.parse(cartData);
                let productObj = {
                    productData,
                    uuid: uuidv4(),
                    product_id: productData.id,
                    product_qty: qty,
                    product_description: description
                }
                cartItems = cartItems.concat(productObj);
            } catch (e) {
                console.log("e", e);
            }
        } else {
            let productObj = {
                productData,
                uuid: uuidv4(),
                product_id: productData.id,
                product_qty: qty,
                product_description: description,
            }
            cartItems = cartItems.concat(productObj);
        }
        cartItems = JSON.stringify(cartItems);
        helpers.setLocalStorage("cartItems", cartItems);

    }, removeProductFromCart: function (removeID) {
        let cartData = helpers.getLocalStorage('cartItems');
        if (typeof cartData !== 'undefined' && cartData !== null && cartData !== "") {
            try {
                let cartItems = JSON.parse(cartData);
                cartItems = cartItems.filter((data) => data.uuid !== removeID);
                cartItems = JSON.stringify(cartItems);
                helpers.setLocalStorage("cartItems", cartItems);
                return true;
            } catch (e) {
                console.log("e", e);
            }
        }
    }, clearCart: function () {
        helpers.setLocalStorage("cartItems", null);
        return true;
    }, _pattern: {
        'email': '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
        'plainstring': '^[a-zA-Z_ ]+$',
        'phone': `^[0-9]{10}$`
    },
};

export default helpers;
